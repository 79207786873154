/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import ClinicalTrials from "../../components/dashboardExhibit/clinicalTrials";
import HealthHistory from "../../components/dashboardExhibit/healthHistory";
import MedicareOptions from "../../components/dashboardExhibit/medicareOptions";
import RxHistory from "../../components/dashboardExhibit/rxHistory";
import RxInteractions from "../../components/dashboardExhibit/rxInteractions";
import RxSavings from "../../components/dashboardExhibit/rxSavings";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import Sidebar from "../../components/sidebar/Sidebar";
import patientFetches from "../../useFetch/userFetch/patientFetches";
import Auth from "../../utils/auth";

function SubHeaderMessage({ hasInsurance }) {
  const linkToInsuranceSelectPage = <a href="/insuranceSelect">link</a>;
  if (hasInsurance) {
    return (
      <p className="m-0 text-grayColor">Questions so far? Give as a call! +1 (442) 271-7998</p>
    );
  }
  if (!hasInsurance) {
    return (
      <p className="m-0 text-grayColor">
        To get started, {linkToInsuranceSelectPage} your insurance provider or call us at +1 (442)
        271-7998
      </p>
    );
  }
}
export default function Dashboard() {
  const [userFirstName, setUserFirstName] = useState("");
  const [patientEOBData, setPatientEOBData] = useState([]);
  const [patientDrugList, setPatientDrugList] = useState([]);
  const [hasInsurance, setHasInsurance] = useState(false);
  const [toasted, setToasted] = useState(false);
  const [fetchStatus, setFetchStatus] = useState({
    planRanks: "pending",
    healthHistory: "pending",
    rxHistory: "pending",
    rxInteractions: "pending",
    clinicalTrials: "pending"
  });

  useEffect(() => {
    (async function initialPatientFetch() {
      if (Auth.loggedIn()) {
        const { email } = Auth.getUser().data;
        const JWT = Auth.getToken();
        try {
          const patientInfo = await patientFetches.retrieveUserInfo(JWT);

          const patientFirstName = await patientFetches.getPatientFirstName(JWT);
          setUserFirstName(patientFirstName);

          const patientEOBDataDB = await patientFetches.getPatientEOB(JWT);
          setPatientEOBData(patientEOBDataDB);

          const patientDrugListFetchData = await patientFetches.getPatientDrugs(JWT);
          setPatientDrugList(patientDrugListFetchData);

          if (!patientInfo && !patientEOBData.length) {
            Mixpanel("Dashboard Without Results");
            ReactGATracking("Page View", "Dashboard Without Results");
            ReactPixelInitialize("Dashboard Without Results", { value: email });
            setHasInsurance(false);
            return;
          }
          Mixpanel("Dashboard With Results");
          ReactGATracking("Page View", "Dashboard With Results");
          ReactPixelInitialize("Dashboard With Results", { value: email });
          setHasInsurance(true);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);

  useEffect(() => {
    // if all fetches are complete, check for errors. If there is at least one error, display a toast
    // if there are any pending, or all are successful, do nothing
    let foundError = false;
    for (const key in fetchStatus) {
      if (fetchStatus[key] === "pending") {
        return;
      }
      if (fetchStatus[key] === "failed") {
        foundError = true;
      }
    }
    // using toasted state to only toast errors once
    if (foundError && !toasted) {
      toast.error("There was an error retrieving some of your data. Please try again later");
      setToasted(true);
    }
  }, [fetchStatus]);

  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }
  return (
    <div className="min-h-screen bg-grayColor">
      <Sidebar>
        <DashboardHeader withBackButton={false} title={`Welcome ${userFirstName}`}>
          <p className="m-0 text-grayColor">
            You&apos;re currently viewing your healthcare dashboard. <br />
          </p>
          <SubHeaderMessage hasInsurance={hasInsurance} />
        </DashboardHeader>
        <div className="flex flex-col 2xl:flex-row">
          <div id="rxHistory" className="flex 2xl:w-1/2">
            <RxHistory setFetchStatus={setFetchStatus} dashboardView />
          </div>
          <div id="rxInteractions" className="flex 2xl:w-1/2">
            <RxInteractions
              setFetchStatus={setFetchStatus}
              dashboardView
              patientDrugList={patientDrugList}
              setPatientDrugList={setPatientDrugList}
            />
          </div>
        </div>

        <div>
          <div id="medicareOptions" className="flex 2xl:w-full">
            <MedicareOptions setFetchStatus={setFetchStatus} dashboardView />
          </div>
        </div>
        <div id="rxSavings">
          <RxSavings />
        </div>
        <div id="healthHistory">
          <HealthHistory setFetchStatus={setFetchStatus} dashboardView />
        </div>
        <div id="clinicalTrials">
          <ClinicalTrials setFetchStatus={setFetchStatus} dashboardView />
        </div>
      </Sidebar>
    </div>
  );
}
