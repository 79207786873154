/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useBreakpoints } from "react-breakpoints-hook";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Milliman from "../../useFetch/millimanFetch/millimanFetch";
import Auth from "../../utils/auth";
import formatMoney from "../../utils/formatMoney";
import useScreenSize from "../../utils/useScreenSize";
import Button from "../button/Button";
import CurrentPlanCard from "../cards/currentPlanCard";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";
import Table from "../table/table";
import Tooltip from "../tooltips/tooltipLogic";
import tooltipObject from "../tooltips/tooltipObject";
import DashboardExhibitContainer from "./dashboardExhibitContainer";

export default function PlanRankTable({ setFetchStatus, dashboardView }) {
  const [planRanks, setPlanRanks] = useState([]);
  const [planRankTitleTooltip, setPlanRankTitleTooltip] = useState(false);
  const [planHeaderTooltip, setPlanHeaderTooltip] = useState(false);
  const [carrierHeaderTooltip, setCarrierHeaderTooltip] = useState(false);
  const [premiumHeaderTooltip, setPremiumHeaderTooltip] = useState(false);
  const [pharmacyHeaderTooltip, setPharmacyHeaderTooltip] = useState(false);
  const [medicalHeaderTooltip, setMedicalHeaderTooltip] = useState(false);
  const [totalHeaderTooltip, setTotalHeaderTooltip] = useState(false);
  const [costCompareTooltip, setCostCompareTooltip] = useState(false);
  const [status, setStatus] = useState("loading");
  const [loadCount, setLoadCount] = useState(10);
  const [renderedArray, setRenderedArray] = useState([]);

  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const loadMore = () => {
    // Increase the count by 10 on each click
    setLoadCount((prevCount) => prevCount + 10);
  };

  const redirectToPlanDetails = (planArray, bidID) => {
    // build the endpoint and search params
    const endpoint = "/planDetails";
    // tracking
    Mixpanel("Plan Details Page");
    ReactGATracking("Page View", "Plan Details Page");
    ReactPixelInitialize("Plan Details Page", { value: "none" });
    const queryParams = {
      name: planArray[1],
      carrier: planArray[2],
      planType: planArray[3],
      network: planArray[4],
      totalEstCost: planArray[5],
      premium: planArray[6],
      medicalPremium: planArray[7],
      pharmacyPremium: planArray[8],
      estMedCost: planArray[9],
      estPharmCost: planArray[10],
      monthlyPremium: planArray[11],
      estMonthlyMedCost: planArray[12],
      estMonthlyPharmCost: planArray[13],
      msaDeposit: planArray[14],
      suppValue: planArray[15],
      netImpact: planArray[16],
      hNumber: bidID.split("_")[0],
      planIdentifier: bidID.split("_")[1]
    };
    // the base url is required to construct a URL object, so we use example.com as place holder. it won't be used in the final url
    const url = new URL(endpoint, "https://example.com");

    Object.keys(queryParams).forEach((key) => {
      url.searchParams.append(key, queryParams[key]);
    });

    const finalURL = url.pathname + url.search;
    navigate(finalURL);
  };

  const { sm, md } = useBreakpoints({
    sm: { min: 0, max: 640 },
    md: { min: 641, max: 1400 }
  });

  useEffect(() => {
    (async function planRankFetch() {
      if (Auth.loggedIn()) {
        const userToken = Auth.getToken();
        try {
          // trigger the milliman workflow and get/set the plan ranks
          const retrievedPlanRanks = await Milliman.trigger(userToken);
          if (retrievedPlanRanks === "failed" || retrievedPlanRanks === "missing") {
            setFetchStatus((previousState) => ({
              ...previousState,
              planRanks: retrievedPlanRanks
            }));
            setStatus(retrievedPlanRanks);
            return;
          }
          setPlanRanks([...retrievedPlanRanks]);
          setRenderedArray(retrievedPlanRanks.slice(0, loadCount));
          setFetchStatus((previousState) => ({
            ...previousState,
            planRanks: "success"
          }));
          setStatus("complete");
        } catch (err) {
          console.error(err);
          setFetchStatus((previousState) => ({
            ...previousState,
            planRanks: "failed"
          }));
          setStatus("failed");
        }
      }
    })();
  }, []);

  useEffect(() => {
    setRenderedArray(planRanks.slice(0, loadCount));
  }, [loadCount]);

  const renderTableData = () => {
    if (status === "loading") {
      const loadingSkeletonArray = new Array(7);
      if (!dashboardView) {
        loadingSkeletonArray.fill([
          <LoadingSkeleton className="mt-2" key="1" />,
          <LoadingSkeleton className="mt-2" key="2" />,
          <LoadingSkeleton className="mt-2" key="3" />,
          <LoadingSkeleton className="mt-2" key="4" />,
          <LoadingSkeleton className="mt-2" key="5" />,
          <LoadingSkeleton className="mt-2" key="6" />
        ]);
      } else {
        loadingSkeletonArray.fill([
          <LoadingSkeleton className="mt-2" key="1" />,
          <LoadingSkeleton className="mt-2" key="2" />
        ]);
      }
      return loadingSkeletonArray;
    }
    if (status === "complete") {
      const medicareOptionsToMap = dashboardView ? renderedArray.slice(0, 5) : renderedArray;
      const graphBar = (plan) => {
        const array = [];
        for (let i = 0; i < 5; i++) {
          array.push(planRanks[i].netImpact);
        }
        const lowestCost = Math.min(...array);
        const withoutDollarSign = plan.replace("$", "");
        const withoutComma = withoutDollarSign.replace(",", "");
        let sizeAdjuster;
        if (md || sm) {
          sizeAdjuster = 50;
        } else {
          sizeAdjuster = 100;
        }
        const costReduced = withoutComma - lowestCost + sizeAdjuster;
        return (
          <div>
            <hr
              style={{
                maxWidth: costReduced + "px"
              }}
              className={` h-10 bg-thirdAccentColor`}
            />
          </div>
        );
      };
      if (!dashboardView) {
        return medicareOptionsToMap.map((plan) => [
          <div onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            {" "}
            {plan.columns[1]}
          </div>,
          plan.columns[2],
          <>
            {plan.columns[6]} ({plan.columns[11]}/month)
          </>,
          <>
            {plan.columns[10]} ({plan.columns[13]}/month)
          </>,
          <>
            {plan.columns[9]} ({plan.columns[12]}/month)
          </>,
          <>
            {plan.columns[5]} ({formatMoney.extractMonthlyPayment(plan.columns[5])}/month)
          </>
        ]);
      }
      if (screenSize.width >= 1000) {
        return medicareOptionsToMap.map((plan) => [
          <div onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            {" "}
            {plan.columns[1]}
          </div>,
          <Button onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            Learn More
          </Button>,
          <div>
            {plan.columns[5]} ({formatMoney.extractMonthlyPayment(plan.columns[5])}/month){" "}
          </div>,
          <span>{graphBar(plan.columns[5])}</span>
        ]);
      }
      if (screenSize.width < 1000) {
        return medicareOptionsToMap.map((plan) => [
          <div onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
            {" "}
            <span className="mx-1 -my-1"> {plan.columns[1]} </span>
            {graphBar(plan.columns[5])}
            <span className="mx-2 flex justify-end -mb-8">
              {plan.columns[5]}({formatMoney.extractMonthlyPayment(plan.columns[5])}/month){" "}
              <Button
                className="ml-12"
                onClick={() => redirectToPlanDetails(plan.columns, plan.bidId)}>
                Learn More
              </Button>
            </span>
          </div>
        ]);
      }
    }
    return null;
  };

  const renderTableHeaders = () => {
    if (!dashboardView) {
      return [
        <div className="whitespace-nowrap">
          Plan
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setPlanHeaderTooltip(true)}
            onMouseLeave={() => setPlanHeaderTooltip(false)}
          />
          {planHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.planHeader}
              size="small"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Carrier
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setCarrierHeaderTooltip(true)}
            onMouseLeave={() => setCarrierHeaderTooltip(false)}
          />
          {carrierHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.carrierHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Premium
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setPremiumHeaderTooltip(true)}
            onMouseLeave={() => setPremiumHeaderTooltip(false)}
          />
          {premiumHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.premiumHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Pharmacy
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setPharmacyHeaderTooltip(true)}
            onMouseLeave={() => setPharmacyHeaderTooltip(false)}
          />
          {pharmacyHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.pharmacyHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Medical
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setMedicalHeaderTooltip(true)}
            onMouseLeave={() => setMedicalHeaderTooltip(false)}
          />
          {medicalHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.medicalHeader}
              size="beneathInline"
            />
          )}
        </div>,
        <div className="whitespace-nowrap">
          Total Cost
          <FaRegCircleQuestion
            className="text-sm ml-2"
            onMouseOver={() => setTotalHeaderTooltip(true)}
            onMouseLeave={() => setTotalHeaderTooltip(false)}
          />
          {totalHeaderTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.totalHeader}
              size={sm ? "beneathInline" : "large"}
            />
          )}
        </div>
      ];
    }
    return [
      <div className="whitespace-nowrap">
        Plan
        <FaRegCircleQuestion
          className="text-sm ml-2"
          onMouseOver={() => setPlanHeaderTooltip(true)}
          onMouseLeave={() => setPlanHeaderTooltip(false)}
        />
        {planHeaderTooltip && (
          <Tooltip
            className="tooltipContainer flex items-center"
            text={tooltipObject.planHeader}
            size="small"
          />
        )}
      </div>,
      <div className="whitespace-nowrap" />,
      <div className="whitespace-nowrap">
        Total Cost
        <FaRegCircleQuestion
          className="text-sm ml-2"
          onMouseOver={() => setTotalHeaderTooltip(true)}
          onMouseLeave={() => setTotalHeaderTooltip(false)}
        />
        {totalHeaderTooltip && (
          <Tooltip
            className="tooltipContainer flex items-center"
            text={tooltipObject.totalHeader}
            size={sm ? "beneathInline" : "large"}
          />
        )}
      </div>,
      <div className="whitespace-nowrap">
        Cost Compare
        <FaRegCircleQuestion
          className="text-sm ml-2"
          onMouseOver={() => setCostCompareTooltip(true)}
          onMouseLeave={() => setCostCompareTooltip(false)}
        />
        {costCompareTooltip && (
          <Tooltip
            className="tooltipContainer flex items-center"
            text={tooltipObject.costCompare}
            size={sm ? "beneathInline" : "large"}
          />
        )}
      </div>
    ];
  };

  return (
    <DashboardExhibitContainer
      title={
        <div>
          Medicare Options
          <FaRegCircleQuestion
            className="text-sm mb-2 ml-2"
            onMouseOver={() => setPlanRankTitleTooltip(true)}
            onMouseLeave={() => setPlanRankTitleTooltip(false)}
          />
          {planRankTitleTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.planRankTableTitle}
              size="beneathInline"
            />
          )}
        </div>
      }
      subtitle="We found the best health plans for you based on your health history, prescription history and zip code."
      viewAll={dashboardView}
      viewAllCount={planRanks.length}
      viewAllParam="/medicareOptions">
      <Table
        tableDivClass="universalTableDivClass"
        tableClass={`${dashboardView ? "planRankMobileDashboard" : "planRankMobile"} ${
          !planRanks.length && "noDataHeaders"
        } universalTable`}
        headers={renderTableHeaders()}
        data={renderTableData()}
      />
      {!dashboardView && planRanks.length > loadCount && (
        <Button className="rounded-b-md rounded-t-none text-base" fullWidth onClick={loadMore}>
          Load more...
        </Button>
      )}
      <CurrentPlanCard />
      {status === "missing" && (
        <p className="m-0 p-2">
          No Medicare Options found. Add data by{" "}
          <Link to="/insuranceSelect">linking your insurer.</Link>
        </p>
      )}
      {status === "failed" && (
        <p className="m-0 p-2">
          Something went wrong with fetching your Medicare Options. Please try again later.
        </p>
      )}
    </DashboardExhibitContainer>
  );
}
